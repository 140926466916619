import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import GalleryPage from './components/GalleryPage';
import { Gallery } from './components/Gallery';




function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="gallery" element={<Gallery />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;