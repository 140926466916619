import React from 'react';
import { Outlet, Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GalleryPage from './GalleryPage';
import { NavBar } from './NavBar';

const Home = () => {

  const options  ={
    items:1,
    loop:true,
    autoplay:true,
    autoplaytimeout:4000,
    nav:true,
    dots:true,
    margin:0,
    responsive:{
      1100:{
        items:1,
      },
      724:{
        items:1
      },
      500:{
        items:1
      },
      370:{
        items:1,
        innerWidth:"100%",
        outerHeight:"100%"
      },
    },
  };

  const options2  ={
    items:1,
    loop:true,
    autoplay:true,
    autoplaytimeout:3000,
    nav:true,
    dots:true,
    margin:0,
    responsive:{
      1100:{
        items:2,
      },
      724:{
        items:2
      },
      500:{
        items:1
      },
      370:{
        items:1,
        innerWidth:"100%",
        outerHeight:"100%"
      },
    },
  };

  return (
    <>
  <div className="container-xxl bg-white p-0">
    {/* Spinner Start */}
        
    {/* Spinner End */}
    
    {/* Carousel Start */}
    <div className="container-fluid p-0 mb-5">
      <NavBar/>
      <OwlCarousel className='owl-theme' {...options}>
        <div>
          <img className="img-fluid" src="img/matkids5.jpg" alt="#" />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(0, 0, 0, .2)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h1 className="display-2 text-white animated slideInDown mb-4">
                    The Best Kindergarten School For Your Child
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                    Where future leaders are nutured.
                  </p>
                  <a
                    href="#"
                    className="btn btn-primary rounded-pill py-sm-3 px-sm-5 me-3 animated slideInLeft"
                  >
                    Learn More
                  </a>
                  <a
                    href="#"
                    className="btn btn-dark rounded-pill py-sm-3 px-sm-5 animated slideInRight"
                  >
                    Our Classes
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div>
          <img className="img-fluid" src="img/matkids6.jpg" alt="#" />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(0, 0, 0, .2)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h1 className="display-2 text-white animated slideInDown mb-4">
                    Make A Brighter Future For Your Child
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                  Embarking on a holistic approach to early education, Matkids extends the learning experience beyond the classroom.
                  </p>
                  <a
                    href="#"
                    className="btn btn-primary rounded-pill py-sm-3 px-sm-5 me-3 animated slideInLeft"
                  >
                    Learn More
                  </a>
                  <a
                    href="#"
                    className="btn btn-dark rounded-pill py-sm-3 px-sm-5 animated slideInRight"
                  >
                    Our Classes
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img className="img-fluid" src="img/trip.jpg" alt="#" />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(0, 0, 0, .2)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h1 className="display-2 text-white animated slideInDown mb-4">
                    Exciting school trips
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                  Embarking on a holistic approach to early education, Matkids extends the learning experience beyond the classroom.
                  </p>
                  <a
                    href="#"
                    className="btn btn-primary rounded-pill py-sm-3 px-sm-5 me-3 animated slideInLeft"
                  >
                    Learn More
                  </a>
                  <a
                    href="#"
                    className="btn btn-dark rounded-pill py-sm-3 px-sm-5 animated slideInRight"
                  >
                    Our Classes
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </OwlCarousel>
        
      
    </div>
    {/* Carousel End */}
    
    
    {/* Facilities Start */}
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: 600 }}
        >
          <h1 className="mb-3">School Facilities</h1>
          <p>
          At Matkids, we pride ourselves on providing a stimulating and secure environment that enhances your child's early learning experience. Our state-of-the-art facilities have been thoughtfully designed to spark curiosity and creativity. Bright and engaging classrooms create a welcoming atmosphere where children feel excited to explore. Our outdoor play areas are not just spaces for fun and games but extensions of the learning environment, fostering physical development and social skills. Safety is paramount, and our facilities are equipped with modern security measures to ensure your child's well-being. Additionally, we offer well-equipped learning corners, a dedicated library filled with age-appropriate books, and interactive spaces that encourage hands-on exploration. With a commitment to excellence, our facilities at Matkids provide the perfect backdrop for your child's early educational journey.
          </p>
        </div>
        <div className="row g-4">
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="facility-item">
              <div className="facility-icon bg-primary">
                <span className="bg-primary" />
                <i className="fa fa-bus-alt fa-3x text-primary" />
                <span className="bg-primary" />
              </div>
              <div className="facility-text bg-primary">
                <h3 className="text-primary mb-3">School Bus</h3>
                <p className="mb-0">
                Our school bus service ensures a safe and comfortable journey for your little ones, emphasizing their well-being from the moment they step out their door. 
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="facility-item">
              <div className="facility-icon bg-success">
                <span className="bg-success" />
                <i className="fa fa-futbol fa-3x text-success" />
                <span className="bg-success" />
              </div>
              <div className="facility-text bg-success">
                <h3 className="text-success mb-3">Playground</h3>
                <p className="mb-0">
                The playground, a haven of laughter and joy, is a dynamic space designed for both structured activities and imaginative play, promoting physical development and social interaction.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="facility-item">
              <div className="facility-icon bg-warning">
                <span className="bg-warning" />
                <i className="fa fa-home fa-3x text-warning" />
                <span className="bg-warning" />
              </div>
              <div className="facility-text bg-warning">
                <h3 className="text-warning mb-3">Healthy Canteen</h3>
                <p className="mb-0">
                Our vibrant canteen offers nutritious meals, carefully curated to fuel young minds with wholesome goodness, supporting a healthy and balanced lifestyle. 
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="facility-item">
              <div className="facility-icon bg-info">
                <span className="bg-info" />
                <i className="fa fa-chalkboard-teacher fa-3x text-info" />
                <span className="bg-info" />
              </div>
              <div className="facility-text bg-info">
                <h3 className="text-info mb-3">Positive Learning</h3>
                <p className="mb-0">
                At Matkids, we believe in fostering a love for learning through a blend of enriching experiences, creating an environment where curiosity thrives, and every child discovers the joy of exploration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Facilities End */}
    {/* About Start */}
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <h1 className="mb-4">
              Learn More About Our Work And Our Cultural Activities
            </h1>
            <p>
            Welcome to our enchanting world at Matkids, where giggles, curiosity, and joy echo through the halls! Nestled in a nurturing environment, our kindergarten embraces the magic of early childhood education, fostering a love for learning that lasts a lifetime.
            </p>
            <p className="mb-4">
            At Matkids, we believe in the power of play, exploration, and creativity to cultivate young minds. Our dedicated team of experienced educators is committed to providing a safe and stimulating space for your little ones to grow, learn, and build lasting friendships. Together, we embark on a journey of discovery, unlocking the potential within each child and laying the foundation for a bright future. Join us in this delightful adventure, where every day is an opportunity for smiles, wonder, and educational milestones!
            </p>
            <div className="row g-4 align-items-center">
              <div className="col-sm-6">
                <a className="btn btn-primary rounded-pill py-3 px-5" href="#">
                  Read More
                </a>
              </div>
              <div className="col-sm-6">
                <div className="d-flex align-items-center">
                  <img
                    className="rounded-circle flex-shrink-0"
                    src="img/user.jpg"
                    alt=""
                    style={{ width: 45, height: 45 }}
                  />
                  <div className="ms-3">
                    <h6 className="text-primary mb-1">Milly Matibo</h6>
                    <small>Director</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 about-img wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="row">
              <div className="col-12 text-center">
                <img
                  className="img-fluid w-75 rounded-circle bg-light p-3"
                  src="img/matkids3.jpg"
                  alt=""
                />
              </div>
              <div className="col-6 text-start" style={{ marginTop: "-150px" }}>
                <img
                  className="img-fluid w-100 rounded-circle bg-light p-3"
                  src="img/matkids4.jpg"
                  alt=""
                />
              </div>
              <div className="col-6 text-end" style={{ marginTop: "-150px" }}>
                <img
                  className="img-fluid w-100 rounded-circle bg-light p-3"
                  src="img/matkids5.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* About End */}
    {/* Call To Action Start */}
    <div className="container-xxl py-5">
      <div className="container">
        <div className="bg-light rounded">
          <div className="row g-0">
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-delay="0.1s"
              style={{ minHeight: 400 }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded"
                  src="img/tw.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="h-100 d-flex flex-column justify-content-center p-5">
                <h1 className="mb-4">Become A Teacher</h1>
                <p className="mb-4">
                Our passionate team of educators at Matkids is the heart of our vibrant learning community.Join us today!
                </p>
                <a className="btn btn-primary py-3 px-5" href="#">
                  Get Started Now
                  <i className="fa fa-arrow-right ms-2" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Call To Action End */}
    {/* Classes Start */}
    {/* Classes End */}
    {/* Appointment Start */}
    <div className="container-xxl py-5">
      <div className="container">
        <div className="bg-light rounded">
          <div className="row g-0">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="h-100 d-flex flex-column justify-content-center p-5">
                <h1 className="mb-4">Make Appointment</h1>
                <form>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control border-0"
                          id="gname"
                          placeholder="Gurdian Name"
                        />
                        <label htmlFor="gname">Gurdian Name</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control border-0"
                          id="gmail"
                          placeholder="Gurdian Email"
                        />
                        <label htmlFor="gmail">Gurdian Email</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control border-0"
                          id="cname"
                          placeholder="Child Name"
                        />
                        <label htmlFor="cname">Child Name</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control border-0"
                          id="cage"
                          placeholder="Child Age"
                        />
                        <label htmlFor="cage">Child Age</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control border-0"
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: 100 }}
                          defaultValue={""}
                        />
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-delay="0.5s"
              style={{ minHeight: 400 }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded"
                  src="img/matkids2.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Appointment End */}
    {/* Team Start */}
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: 600 }}
        >
          <h1 className="mb-3">Popular Teachers</h1>
          <p>
          Our passionate team of educators at Matkids is the heart of our vibrant learning community. Committed to nurturing the boundless potential within each child, our teachers bring a perfect blend of warmth, expertise, and creativity to the classrooms. Trained in early childhood education, our devoted staff understands the importance of creating a nurturing environment where curiosity flourishes. With a focus on personalized attention, our teachers not only impart knowledge but also inspire a love for learning. They skillfully weave together play, exploration, and academic concepts, ensuring that every child feels supported and encouraged on their educational journey. Together, our dedicated teaching team is here to make each day at Matkids a memorable and enriching experience for your little ones.
          </p>
        </div>
        <div className="row g-4">
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-item position-relative">
              <img
                className="img-fluid rounded-circle w-75"
                src="img/team-1.jpg"
                alt=""
              />
              <div className="team-text">
                <h3>Full Name</h3>
                <p>Designation</p>
                <div className="d-flex align-items-center">
                  <a
                    className="btn btn-square btn-primary mx-1"
                    href="#"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-square btn-primary  mx-1"
                    href="#"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-square btn-primary  mx-1"
                    href="#"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="team-item position-relative">
              <img
                className="img-fluid rounded-circle w-75"
                src="img/team-2.jpg"
                alt=""
              />
              <div className="team-text">
                <h3>Full Name</h3>
                <p>Designation</p>
                <div className="d-flex align-items-center">
                  <a
                    className="btn btn-square btn-primary mx-1"
                    href="#"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-square btn-primary  mx-1"
                    href="#"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-square btn-primary  mx-1"
                    href="#"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item position-relative">
              <img
                className="img-fluid rounded-circle w-75"
                src="img/team-3.jpg"
                alt=""
              />
              <div className="team-text">
                <h3>Full Name</h3>
                <p>Designation</p>
                <div className="d-flex align-items-center">
                  <a
                    className="btn btn-square btn-primary mx-1"
                    href="#"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-square btn-primary  mx-1"
                    href="#"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-square btn-primary  mx-1"
                    href="#"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Team End */}
    {/* Testimonial Start */}
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: 600 }}
        >
          <h1 className="mb-3">Our Clients Say!</h1>
          <p>
            Good School
          </p>
        </div>
        <OwlCarousel className='owl-theme' {...options2}>
          <div className="testimonial-item bg-light rounded p-5">
            <p className="fs-5">
              Lovely classrooms
            </p>
            <div
              className="d-flex align-items-center bg-white me-n5"
              style={{ borderRadius: "50px 0 0 50px" }}
            >
              <img
                className="img-fluid flex-shrink-0 rounded-circle"
                src="img/testimonial-1.jpg"
                style={{ width: 90, height: 90 }}
              />
              <div className="ps-3">
                <h3 className="mb-1">Client Name</h3>
                <span>Profession</span>
              </div>
              <i className="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex" />
            </div>
          </div>
          <div className="testimonial-item bg-light rounded p-5">
            <p className="fs-5">
              Excellent teachers
            </p>
            <div
              className="d-flex align-items-center bg-white me-n5"
              style={{ borderRadius: "50px 0 0 50px" }}
            >
              <img
                className="img-fluid flex-shrink-0 rounded-circle"
                src="img/testimonial-2.jpg"
                style={{ width: 90, height: 90 }}
              />
              <div className="ps-3">
                <h3 className="mb-1">Client Name</h3>
                <span>Profession</span>
              </div>
              <i className="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex" />
            </div>
          </div>
          <div className="testimonial-item bg-light rounded p-5">
            <p className="fs-5">
              Very good learning environment
            </p>
            <div
              className="d-flex align-items-center bg-white me-n5"
              style={{ borderRadius: "50px 0 0 50px" }}
            >
              <img
                className="img-fluid flex-shrink-0 rounded-circle"
                src="img/testimonial-3.jpg"
                style={{ width: 90, height: 90 }}
              />
              <div className="ps-3">
                <h3 className="mb-1">Client Name</h3>
                <span>Profession</span>
              </div>
              <i className="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex" />
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
    {/* Testimonial End */}
    {/* School Location */}
    <div>
    
    <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127672.32515943806!2d36.0616237390625!3d-0.30936449999999965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1829916549f9238f%3A0x417374c3ad3aaa78!2sNakuru%20East%2C%20Nakuru!5e0!3m2!1sen!2ske!4v1704284734525!5m2!1sen!2ske" 
      width="100%"
      height="650" 
      style={{border:0}} 
      allowfullscreen="" 
      loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade">

    </iframe>
    </div>
    {/* Footer Start */}
    <div
      className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h3 className="text-white mb-4">Get In Touch</h3>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3" />
              Location, Nakuru, Kenya
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3" />
              (+254)795107713
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3" />
              info@example.com
            </p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-outline-light btn-social"
                href="#"
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href="#"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href="#"
              >
                <i className="fab fa-youtube" />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href="#"
              >
                <i className="fab fa-linkedin-in" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h3 className="text-white mb-4">Quick Links</h3>
            <a className="btn btn-link text-white-50" href="">
              About Us
            </a>
            <a className="btn btn-link text-white-50" href="">
              Contact Us
            </a>
            <a className="btn btn-link text-white-50" href="">
              Our Services
            </a>
            <a className="btn btn-link text-white-50" href="">
              Privacy Policy
            </a>
            <a className="btn btn-link text-white-50" href="">
              Terms &amp; Condition
            </a>
          </div>
          <div className="col-lg-3 col-md-6">
            <h3 className="text-white mb-4">Photo Gallery</h3>
            <div className="row g-2 pt-2">
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids2.jpg"
                  alt=""
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids6.jpg"
                  alt=""
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids3.jpg"
                  alt=""
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids5.jpg"
                  alt=""
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids4.jpg"
                  alt=""
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids7.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h3 className="text-white mb-4">Newsletter</h3>
            <p>Subscribe to our newsletter.</p>
            <div
              className="position-relative mx-auto"
              style={{ maxWidth: 400 }}
            >
              <input
                className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Your email"
              />
              <button
                type="button"
                className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                SignUp
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©{" "}
              <a
                className="border-bottom"
                href="#"
              >
                Matkids
              </a>
              , All Right Reserved.

            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a href="">Home</a>
                <a href="">Cookies</a>
                <a href="">Help</a>
                <a href="">FQAs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Footer End */}
    {/* Back to Top */}
    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
      <i className="bi bi-arrow-up" />
    </a>
  </div>
  {/* JavaScript Libraries */}
  {/* Template Javascript */}
</>

  );
};

export default Home;