import React from 'react'
import { Outlet, Link } from "react-router-dom";
export const NavBar = () => {
  return (
    <div className="container-fluid p-0 mb-5">
        {/* Navbar Start */}
    <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
      <a href="#" className="navbar-brand">
        <h1 className="m-0 text-primary">
          <i className="fa fa-book-reader me-3" />
          Matkids
        </h1>
      </a>
      <button
        type="button"
        className="navbar-toggler"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav mx-auto">
          <Link to="/" className="nav-item nav-link active">Home</Link>

          <a href="#" className="nav-item nav-link">
            About Us
          </a>
          <Link to="/gallery" className="nav-item nav-link">Gallery</Link>
          <a href="#" className="nav-item nav-link">
            Classes
          </a>
          <div className="nav-item dropdown">
            <a
              href="#"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              Pages
            </a>
            <div className="dropdown-menu rounded-0 rounded-bottom border-0 shadow-sm m-0">
              <a href="#" className="dropdown-item">
                School Facilities
              </a>
              <a href="#" className="dropdown-item">
                Popular Teachers
              </a>
              <a href="#" className="dropdown-item">
                Become A Teachers
              </a>
              <a href="#" className="dropdown-item">
                Make Appointment
              </a>
              <a href="#" className="dropdown-item">
                Testimonial
              </a>
              <a href="#" className="dropdown-item">
                404 Error
              </a>
            </div>
          </div>
          <a href="#" className="nav-item nav-link">
            Contact Us
          </a>
        </div>
        <a
          href="#"
          className="btn btn-primary rounded-pill px-3 d-none d-lg-block"
        >
          Join Us
          <i className="fa fa-arrow-right ms-3" />
        </a>
      </div>
    </nav>
    <Outlet/>
    {/* Navbar End */}
    </div>
  )
}
