  import { useState } from 'react'

  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import {
    faCircleChevronLeft, 
    faCircleChevronRight, 
    faCircleXmark
  } from '@fortawesome/free-solid-svg-icons'
  
  import './wsp-gallery.css'


/* popout the browser and maximize to see more rows! -> */
const photos = [
    {
      src: 'img/trip.jpg',
      width: 1,
      height: 1
    },
    {
      src: 'img/matkids2.jpg',
      width: 1,
      height: 1
    },
    {
        src: 'img/matkids3.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/matkids4.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/matkids5.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/matkids6.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/matkids7.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/matkids8.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/matkids10.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/matkids11.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/matkids12.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/hill.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/play.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/rail.jpg',
        width: 1,
        height: 1
    },
    {
        src: 'img/tw.jpg',
        width: 1,
        height: 1
    },

  ];
  
  const GalleryPage = ({photos}) => {
  
    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)
  
    const handleOpenModal = (index) => {
      setSlideNumber(index)
      setOpenModal(true)
    }
  
    // Close Modal
    const handleCloseModal = () => {
      setOpenModal(false)
    }
  
    // Previous Image
    const prevSlide = () => {
      slideNumber === 0 
      ? setSlideNumber( photos.length -1 ) 
      : setSlideNumber( slideNumber - 1 )
    }
  
    // Next Image  
    const nextSlide = () => {
      slideNumber + 1 === photos.length 
      ? setSlideNumber(0) 
      : setSlideNumber(slideNumber + 1)
    }
  
    return (
      <div>
  
        {openModal && 
          <div className='sliderWrap'>
            <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal}  />
            <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
            <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
            <div className='fullScreenImage'>
              <img src={photos[slideNumber].img} alt='' />
            </div>
          </div>
        }
  
        {/* <br />
        Current slide number:  {slideNumber}
        <br />
        Total Slides: {photos.length}
        <br /><br /> */}
  
        <div className='galleryWrap'>
          {
            photos && photos.map((slide, index) => {
              return(
                <div 
                  className='single' 
                  key={index}
                  onClick={ () => handleOpenModal(index) }
                >
                  <img src={slide.img} alt='' />
                </div>
              )
            })
          }
        </div>
  
      </div>
    )
  }
  
  export default GalleryPage;