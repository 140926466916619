import React from 'react';
import GalleryPage from './GalleryPage';
import { NavBar } from './NavBar';

export const Gallery = () => {
    const photos = [
        {
          img: 'img/trip.jpg'
        },
        {
          img: 'img/matkids2.jpg'
        },
        {
            img: 'img/matkids3.jpg',
        },
        {
            img: 'img/matkids4.jpg',
        },
        {
            img: 'img/matkids5.jpg',
        },
        {
            img: 'img/matkids6.jpg',
        },
        {
            img: 'img/matkids7.jpg',
        },
        {
            img: 'img/matkids8.jpg',
        },
        {
            img: 'img/matkids10.jpg',
        },
        {
            img: 'img/matkids11.jpg',
        },
        {
            img: 'img/matkids12.jpg',
        },
        {
            img: 'img/hill.jpg',
        },
        {
            img: 'img/play.jpg',
        },
        {
            img: 'img/rail.jpg',
        },
        {
            img: 'img/tw.jpg',
        },
      
      ];
  return (
    <div className="container-xxl bg-white p-0">
    <NavBar/>
    <div>
        <h3 style={{textAlign:"center"}}>Our Gallery</h3>
        <div>
            <GalleryPage photos={photos}/>
        </div>
    </div>
    <div
      className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h3 className="text-white mb-4">Get In Touch</h3>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3" />
              Location, Nakuru, Kenya
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3" />
              (+254)795107713
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3" />
              info@example.com
            </p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-outline-light btn-social"
                href="#"
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href="#"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href="#"
              >
                <i className="fab fa-youtube" />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href="#"
              >
                <i className="fab fa-linkedin-in" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h3 className="text-white mb-4">Quick Links</h3>
            <a className="btn btn-link text-white-50" href="">
              About Us
            </a>
            <a className="btn btn-link text-white-50" href="">
              Contact Us
            </a>
            <a className="btn btn-link text-white-50" href="">
              Our Services
            </a>
            <a className="btn btn-link text-white-50" href="">
              Privacy Policy
            </a>
            <a className="btn btn-link text-white-50" href="">
              Terms &amp; Condition
            </a>
          </div>
          <div className="col-lg-3 col-md-6">
            <h3 className="text-white mb-4">Photo Gallery</h3>
            <div className="row g-2 pt-2">
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids2.jpg"
                  alt=""
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids6.jpg"
                  alt=""
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids3.jpg"
                  alt=""
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids5.jpg"
                  alt=""
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids4.jpg"
                  alt=""
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid rounded bg-light p-1"
                  src="img/matkids7.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h3 className="text-white mb-4">Newsletter</h3>
            <p>Subscribe to our newsletter.</p>
            <div
              className="position-relative mx-auto"
              style={{ maxWidth: 400 }}
            >
              <input
                className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Your email"
              />
              <button
                type="button"
                className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                SignUp
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©{" "}
              <a
                className="border-bottom"
                href="#"
              >
                Matkids
              </a>
              , All Right Reserved.

            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a href="">Home</a>
                <a href="">Cookies</a>
                <a href="">Help</a>
                <a href="">FQAs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
